<template>
  <div id="app">
    <!-- 标题 -->
    <div class="title">{{ tableData.activityName }}</div>
    <div class="time">{{ time }}</div>
    <!-- 统计行 -->
    <div class="line">
      <div class="item">
        <div class="img">
          <img src="./assets/1.png" alt="" />
        </div>
        <div class="msg">
          <div class="label">到场人才数</div>
          <div>
            <el-progress
              :percentage="50"
              color="#1d93e1"
              define-back-color="#0f3470"
              :show-text="false"
              stroke-width="10"
            />
          </div>
          <div class="num">
            {{ tableData.presentTotal }}<span class="dw">人</span>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="img"><img src="./assets/2.png" /></div>
        <div class="msg">
          <div class="label">招聘企业数</div>
          <div>
            <el-progress
              :percentage="50"
              color="#eb7b15"
              define-back-color="#0f3470"
              :show-text="false"
              stroke-width="10"
            />
          </div>
          <div class="num">
            {{ tableData.companyTotal }}<span class="dw">家</span>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="img"><img src="./assets/2.png" /></div>
        <div class="msg">
          <div class="label">职位需求数</div>
          <div>
            <el-progress
              :percentage="50"
              color="#4ef6ba"
              define-back-color="#0f3470"
              :show-text="false"
              stroke-width="10"
            />
          </div>
          <div class="num">
            {{ tableData.requirementsTotal }}<span class="dw">个</span>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="img"><img src="./assets/2.png" /></div>
        <div class="msg">
          <div class="label">人才需求数</div>
          <div>
            <el-progress
              :percentage="50"
              color="#73d466"
              define-back-color="#0f3470"
              :show-text="false"
              stroke-width="10"
            />
          </div>
          <div class="num">
            {{ tableData.resumeTotal }}<span class="dw">人</span>
          </div>
        </div>
      </div>
    </div>

    <div class="table">
      <!-- 统计图 -->
      <div class="left">
        <div class="label">人才学历构成</div>
        <div ref="ech" id="ech" class="rch"></div>
      </div>
      <!-- 表格内容 -->
      <div class="right">
        <div class="column labelTitle">
          <div class="dy">招聘单位</div>
          <div class="job">招聘职位</div>
          <div class="zy">专业</div>
          <div class="xl">学历</div>
          <div class="nx">年薪(万)</div>
          <div class="hy">行业领域</div>
          <div class="rs">面试人数</div>
        </div>
        <div class="jobBox" ref="scrollContainer">
          <div
            :class="(index + 1) % 2 === 0 ? 'column list2' : 'column list'"
            v-for="(item, index) in tableData.requirementsList"
            :key="item.id"
          >
            <div class="dy">{{ item.customerName }}</div>
            <div class="job">{{ item.job }}</div>
            <div class="zy">{{ item.major }}</div>
            <div class="xl">{{ item.firstDegree }}</div>
            <div class="nx">{{ item.salaryStart }}-{{ item.salaryEnd }}</div>
            <div class="hy">{{ item.industry }}</div>
            <div class="rs">{{ item.peopleCount }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activityNowApi, activityCountApi } from "@/api/index";
export default {
  name: "App",
  components: {},
  data() {
    return {
      tableData: {},
      time: "",
      scrollContainer: null,
      scrollSpeed: 30, // 滚动速度，单位为毫秒
      setTimeSlep: null,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.$nextTick(() => {
      this.getDetial();
      this.setTime();
      setInterval(() => {
        this.setTime();
      }, 1000);
      setInterval(() => {
        this.getDetial();
      }, 10000);
    });
  },
  activated() {},
  methods: {
    async getData() {
      let res = await activityNowApi();
      if (res && res.code === 200) {
        this.getDetial({ activityId: res.data[0].id });
      }
    },
    async getDetial() {
      clearInterval(this.setTimeSlep);
      this.setTimeSlep = null;
      let res = await activityCountApi({ activityId: "1745773281597849600" });
      if (res && res.code === 200) {
        this.tableData = res.data;
        this.drwer();
        if (res.data.requirementsList.length > 11) {
          this.scrollContainer = this.$refs.scrollContainer;
          this.setTimeSlep = setInterval(() => {
            this.autoScroll();
          }, this.scrollSpeed);
        } else {
          clearInterval(this.setTimeSlep);
          this.setTimeSlep = null;
        }
      }
    },
    drwer() {
      let myChart = this.$echarts.init(document.getElementById("ech"));

      let edata = [
        { value: this.tableData.doctorTotal, name: "博士" },
        { value: this.tableData.masterTotal, name: "硕士" },
        { value: this.tableData.regularTotal, name: "本科" },
        { value: this.tableData.juniorTotal, name: "专科及以下" },
      ];
      let option = {
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "",
            type: "pie",
            radius: [20, 100],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 6,
            },
            data: edata,

            label: {
              normal: {
                show: true, //展示
                position: "outside", // outside表示文本显示位置为外部
                textStyle: {
                  color: "#fff", // 改变标示文字的颜色
                  fontSize: 22, //文字大小
                  borderType: "dashed",
                  width: 125,
                },

                borderWidth: 20,
                borderRadius: 4,
                padding: [0, -60],
              },
            },
            labelLine: {
              normal: {
                show: true, //引导线显示
                length: 5,
                length2: 60,
              },
            },
            labelLayout: {
              verticalAlign: "bottom",
              dy: -10,
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    setTime() {
      let currentDate = new Date();

      // 获取年、月、日、时、分、秒
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以要加1
      let day = ("0" + currentDate.getDate()).slice(-2);
      let hours = ("0" + currentDate.getHours()).slice(-2);
      let minutes = ("0" + currentDate.getMinutes()).slice(-2);
      let seconds = ("0" + currentDate.getSeconds()).slice(-2);

      // 格式化时间字符串
      this.time =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
    },
    // 自动滚动
    autoScroll() {
      // 向上滚动
      this.scrollContainer.scrollTop += 1;
      // 当滚动到底部时，回到顶部
      if (
        this.scrollContainer.scrollTop >=
        this.scrollContainer.scrollHeight - this.scrollContainer.clientHeight
      ) {
        this.scrollContainer.scrollTop = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  margin: 0;
}

#app {
  position: relative;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-image: url("./assets/pg.png");
  background-size: 100% 100%;
  .title {
    text-align: center;
    color: #fff;
    font-weight: 400;
    padding-top: 3.5vh;
    font-size: 1.6vw;
  }
  .time {
    color: #fff;
    position: absolute;
    right: 1.7vw;
    top: 13vh;
    color: #29d2fd;
    font-size: 1.2vw;
  }
  .line {
    width: 90%;
    margin: 0 auto;
    padding-top: 10vh;
    display: flex;
    .item {
      position: relative;
      height: 13vh;

      flex: 1;
      .img {
        width: 32%;
        display: inline-block;
        text-align: right;
        margin-right: 2%;
      }
      .msg {
        position: absolute;
        top: 2vh;
        width: 66%;
        display: inline-block;
        color: #fff;
        .label {
          font-size: 1.2vw;
          margin-bottom: 1vh;
        }
        .num {
          margin-left: 0.5vw;
          font-size: 1.5vw;
          color: #29d2fd;
          margin-top: 1vh;
        }
        .dw {
          margin-left: 1vw;
          color: #fff;
          font-size: 1vw;
        }
      }
    }
  }

  .table {
    width: 95vw;
    margin: 5vh auto 0 auto;
    .left,
    .right {
      display: inline-block;
      border-radius: 10px;
    }
    .left {
      background-color: #04195a;
      width: 16%;
      height: 61vh;
      overflow: hidden;
      .label {
        text-align: center;
        color: #fff;
        height: 10vh;
        font-size: 1.2vw;
        line-height: 10vh;
      }
      .rch {
        width: 100%;
        height: 50vh;
      }
    }
    .right {
      overflow: hidden;
      border: 1px solid #1a39a3;
      margin-left: 1vw;
      width: calc(84% - 3px - 1vw);
      height: 61vh;

      background-color: #1a3aa321;
      .column {
        width: 95%;
        padding: 0 2.5%;
        margin: 0 auto;
        height: 5vh;
        line-height: 5vh;
        overflow: hidden;
        > div {
          color: #fff;
          display: inline-block;
          height: 5vh;
          line-height: 5vh;
          overflow: hidden;
        }
        .dy {
          width: 20%;

          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏超出容器尺寸的部分 */
          text-overflow: ellipsis;
        }
        .job {
          width: 25%;

          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏超出容器尺寸的部分 */
          text-overflow: ellipsis;
        }
        .zy {
          width: 12%;
          height: 5vh;
          line-height: 5vh;
          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏超出容器尺寸的部分 */
          text-overflow: ellipsis;
        }
        .xl {
          width: 8%;

          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏超出容器尺寸的部分 */
          text-overflow: ellipsis;
        }
        .nx {
          width: 10%;

          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏超出容器尺寸的部分 */
          text-overflow: ellipsis;
        }
        .hy {
          width: 15%;

          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏超出容器尺寸的部分 */
          text-overflow: ellipsis;
        }
        .rs {
          width: 10%;
          text-align: center;

          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏超出容器尺寸的部分 */
          text-overflow: ellipsis;
        }
      }
      .labelTitle {
        font-size: 1.2vw;

        background-color: #122752;
      }
      .jobBox {
        z-index: 999;
        height: 56vh;
        overflow: hidden;
        overflow-y: auto;
      }
      .jobBox::-webkit-scrollbar {
        display: none;
      }
      .list,
      .list2 {
        font-size: 1vw;
        div {
          color: #29d2fd;
          overflow: hidden;
        }

        .job {
          color: #eb7b15;

          white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏超出容器尺寸的部分 */
          text-overflow: ellipsis;
        }

        .nx {
          color: #eb7b15;
          overflow: hidden;
        }

        .rs {
          color: #9fc237;
          overflow: hidden;
        }
      }
      .list {
        background-color: #112d68;
      }
      .list2 {
        background-color: #122752;
      }
    }
  }
}
</style>
