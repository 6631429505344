import request from '@/utils/request'

export function activityCountApi(data) {
    return request({
        url: '/index/activityCount',
        method: 'post',
        params: data
    })
}

export function activityNowApi(data) {
    return request({
        url: '/activity/now',
        method: 'post',
        data: data
    })
}